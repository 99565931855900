import '/assets/styles/pages/page.scss';
import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

// Templates
import { SidemenuTemplate } from '/views/layouts/sidemenu'

// Sections
import SectionHeading from '/views/sections/sidemenu/section-heading';
import Section from '/views/sections/sidemenu/section';

// Partials
import Loader from '/views/partials/loader'
import EmptyMessage from '/views/partials/empty-message'

// Context
import { useNavigator, useParams } from '@wearetla/tla-essentials-tools/utilities/navigator'
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';

// Services
import siteServices from '/services/site';

const getPageCategories = () => {
	return siteServices.getPageCategories();
}

const getPageDetail = (pageId) => {
	return siteServices.getPageDetail(pageId);
}

const parseHead = (pageData) => {
	return {
		title: (pageData?.title ? pageData.title + ' - ' : '') + 'Sayfalar',
		description: 'IF Performance Hall içerik sayfaları',
	}
}

const Page = ({ pageProps }) => {
	const { redirect } = useNavigator();
	const { mobile } = useBreakpoints();
	const { setHead } = useHead();
	const { setNotFound, setError } = useGlobalState();
	const { id: pageId } = useParams(['page']);

	const [pageData, setPageData] = useState(pageProps?.pageData ?? null);
	const [rawCategories, setCategories] = useState(pageProps?.categories ?? null);

	const categories = useMemo(() => {
		return rawCategories ? rawCategories.map((category) => ({
			href: 'page',
			active: pageId?.toString() === category?.id?.toString(),
			params: {
				slug: category.slug,
				id: category.id,
			},
			title: category.title,
		})) : [false, false, false]
	}, [rawCategories, pageId]);

	useEffect(() => {
		if(!rawCategories) {
			getPageCategories().then(setCategories).catch(() => {
				setError();
			});
		}
	}, [rawCategories])

	useEffect(() => {
		if(mobile && rawCategories?.[0] && !pageId) {
			redirect('page', { slug: rawCategories[0].slug, id: rawCategories[0].id });
		}
	}, [mobile, rawCategories, pageId])


	useEffect(() => {
		if(pageId) {
			if(pageData?.id?.toString?.() !== pageId) {
				setPageData(null);
				getPageDetail(pageId).then((data) => {
					setPageData(data);
				}).catch(() => {
					setNotFound();
				})
			}
		}
		else {
			setPageData(false);
		}
	}, [pageId, pageData])

	useEffect(() => {
		setHead(parseHead(pageData));
	}, [pageData])

	return (
		<SidemenuTemplate
			title="IF"
			menuItems={categories}>
			<Section className="page-content">
					<Loader loading={pageData === null && !!pageId} />
					{pageData === false &&
						<EmptyMessage>Bir sayfa seçin.</EmptyMessage>
					}
					{!!pageData?.description &&
						<>
							<SectionHeading title={pageData?.title ?? 'Sayfalar'} />

							<div className="page-textcontent wysiwyg" dangerouslySetInnerHTML={{ __html: pageData?.description }} />
						</>
					}
			</Section>
		</SidemenuTemplate>
	)
}

Page.propTypes = {
	pageProps: PropTypes.object
}

Page.getServerProps = ({ match }) => {
	return new Promise((resolve) => {
		Promise.all([
			getPageCategories(),
			match.params.id ? getPageDetail(match.params.id) : (new Promise((resolve) => { resolve(false) })),
		]).then(([
			categories,
			pageData,
		]) => {
			resolve({
				pageProps: {
					categories,
					pageData,
				},
				head: parseHead(pageData),
			})
		}).catch(() => {
			resolve({
			})
		})
	});
}

export default Page